.header-box{
  position: fixed;
  top: 0;
  left:0;
  z-index:1100;
  width:100%;
  min-width:1440px;
  height:80px;
  background:#ffffff;
  .header{
    margin: 0 auto;
    max-width:1440px;
    min-width:750px;
    height:80px;
    padding:0 80px;
    background:#ffffff;
    display: flex;
    justify-content:space-between;
    align-items:center;
    .logo{
      width:120px;
    }
    .menu{
      height:100%;
      display: flex;
      align-items: center;
      font-size:16px;
      font-weight:700;
      color:#333333;
      .menu-item{
        margin-right:30px;
        cursor: pointer;
        &.active{
          a{
            color:#5B9069;
          }
        }
        &.lang-box{
          .MuiButton-text{
            height:40px;
            padding:0;
            font-size:16px;
            font-weight:700;
            color:#333333;
            line-height:40px;
            padding-right:18px;
            background:transparent url('../../assets/images/header/arrow-lang-selector.png') no-repeat right center;
            background-size:16px;
            min-width:0;
            font-family: '方正兰亭黑';
            &:hover{
              background-color:transparent;
            }
            span{
              display:none;
            }
          }
        }
        &.media{
          width:40px;
          height:40px;
          margin-right:20px;
          &.opensea{
            background:url('../../assets/images/header/icon-opensea.png') no-repeat center center;
            background-size:cover;
          }
          &.twitter{
            background:url('../../assets/images/header/icon-twitter.png') no-repeat center center;
            background-size:cover;
          }
          &.discord{
            background:url('../../assets/images/header/icon-discord.png') no-repeat center center;
            background-size:cover;
          }
        }
        &.connect{
          width:40px;
          height:40px;
          margin-right:0;
          background:url('../../assets/images/header/wallet-gray.png') no-repeat center center;
          background-size:cover;
          &.connected{
            height:40px;
            padding-left: 43px;
            padding-right: 15px;
            background:#5B9069 url('../../assets/images/header/connected-ball.png') no-repeat 10px center;
            background-size:25px;
            border-radius: 40px;
            line-height:40px;
            color:#FFFFFF;
            font-size:14px;
            width:145px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-word;
          }
        }
      }
    }
  }
}

.lang-selector{
  .MuiPaper-root{
    position:absolute;
    width:100px;
    // z-index:999999;
    
    ul{
      padding:5px 10px;
      background:#5B9069;
      // z-index:999999;
      
      .MuiListItemButton-root{
        padding:8px 0;
        font-size:14px;
        color:#FFFFFF;
        font-weight: bold;
        border-radius: 30px;
        text-align: center;
        justify-content:center;
      }
      .Mui-selected{
        color:#5B9069;
        background:#FFFFFF;
      }
    }
  }
}

.ant-popover{
  .ant-popover-content{
    transform:translate(5px,-5px);
    
    .ant-popover-arrow{
      // display: none;
    }
    .ant-popover-inner{
      border-radius:4px;
      overflow: hidden;
      .ant-popover-inner-content{
        padding:0;
        p{
          padding:0 16px;
          margin:0;
          font-size:14px;
          height:38px;
          line-height:38px;
          &.btn{
            cursor: pointer;
            &:hover{
              background:rgba(0,0,0,0.05);
            }
          }
        }
      }
      
    }
  }


}

// @media screen and (max-width: 1200px) {
//   .header-box{
//     height:60px;
//     .header{
//       height:60px;
//       padding:0 40px;
//       .logo{
//         width:90px;
//       }
//       .menu{
//         font-size:14px;
//         .menu-item{
//           margin-right:30px;
//           &.lang-box{
//             .MuiButton-text{
//               font-size:14px;
//               padding-right:18px;
//               background:transparent url('../../assets/images/header/arrow-lang-selector.png') no-repeat right center;
//               background-size:12px;
//             }
//           }
//           &.media{
//             width:30px;
//             height:30px;
//             margin-right:20px;
//           }
//           &.connect{
//             width:30px;
//             height:30px;
//           }
//         }
//       }
//     }
//   }
// }


.phone-header-box{
  position: fixed;
  top: 0;
  left:0;
  z-index:1100;
  width:100%;
  max-width:100%;
  // min-width:1000px;
  height:37px;
  background:#ffffff;
  .header{
    width:100%;
    margin: 0 auto;
    height:37px;
    padding:0 20px;
    background:#ffffff;
    display: flex;
    justify-content:space-between;
    align-items:center;
    .logo{
      width:50px;
    }
    .header-right{
      display: flex;
      align-items:center;
      justify-content:flex-end;
      .menu-item{
        margin-right:20px;
        font-size:14px;
        color:#333333;
        &.active{
          a{
            color:#5B9069;
          }
        }
      }
      .menu-btn{
        width:18px;
        height:18px;
        background:transparent url('../../assets/images/header/menu-btn.png') no-repeat center center;
        background-size:18px;
        cursor: pointer;
      }
    }
    
  }
  .menu-page{
    position: absolute;
    top: 100%;
    left:0;
    z-index:99;
    width:100%;
    height:calc(100vh - 37px);
    background:rgba(0,0,0,0.75);
    padding:0 20px;
    cursor: pointer;
    &.hide{
      display:none;
    }
    .menu-item{
      display:block;
      padding:35px 0 20px;
      font-size:16px;
      line-height:20px;
      font-weight:700;
      color:#FFFFFF;
      border-bottom:2px solid rgba(255,255,255,0.5);
      cursor: pointer;
      &.media{
        &.opensea{
          background:url('../../assets/images/header/logo-opensea.png') no-repeat right 35px;
          background-size:20px;
        }
        &.twitter{
          background:url('../../assets/images/header/logo-twitter.png') no-repeat right 35px;
          background-size:20px;
        }
        &.discord{
          margin-right:0;
          background:url('../../assets/images/header/logo-discord.png') no-repeat right 35px;
          background-size:20px;
        }
        &.wallet{
          margin-right:0;
          padding-right:50px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-word;
          background:url('../../assets/images/header/wallet.png') no-repeat right 35px;
          background-size:20px;
        }
      }
    }
  }
}
