.page-box{
  max-width: 1440px;
  margin:0 auto;
  position: relative;
  .banner{
    position: relative;
    .banner-bg{
      width:100%;
    }
    .banner-content{
      position: absolute;
      left: 0;
      width:100%;
      top:50%;
      transform: translateY(calc(-50% + 40px));
      &.mint-before{
        // top:34%;
        .mint-date{
          font-size:34px;
          font-weight:700;
          color:#000000;
          text-align:center;
          line-height:60px;
          letter-spacing:2px;
        }
        .mint-intro{
          margin-top:calc(100% * 70 / 1440);
          font-size:18px;
          font-weight:400;
          color:#000000;
          text-align:center;
          line-height:36px;
        }
        .mint-box{
          margin-top:calc(100% * 80 / 1440);
          .mint-btn{
            margin:0 auto;
            width:310px;
            height:66px;
            // border:1px solid rgba(255,255,255,0.7);
            // border-radius:10px;
            // background:rgba(255,255,255,0.15);
            background:url('../../assets/images/index/btn-bg.png') no-repeat center center;
            background-size:100% 100%;
            display:flex;
            align-items: center;
            justify-content: center;
            font-size:30px;
            font-weight:600;
            color:#FFFFFF;
            letter-spacing: 10px;
            opacity: 0.6;
          }
        }
      }
      &.mint{
        // top:34%;
        .mint-info{
          margin: 0 auto;
          width:75%;
          height:60px;
          background:#509162;
          border-radius:33px;
          display: flex;
          justify-content:center;
          align-items:center;
          .mint-info-item{
            height:30px;
            line-height: 30px;
            font-size:20px;
            font-weight:700;
            color:#FFFFFF;
            span{
              font-size:22px;
              letter-spacing:1px;
            }
            &.transaction-amount{
              margin:0 30px;
              padding:0 30px;
              border-left:2px solid #FFFFFF;
              border-right:2px solid #FFFFFF;
            }
          }
        }
        .mint-intro{
          margin-top:calc(100%  * 70 / 1440);
          font-size:18px;
          font-weight:400;
          color:#000000;
          text-align:center;
          line-height:36px;
        }
        .mint-box{
          margin-top:calc(100% * 80 / 1440);
          .mint-btn{
            margin:0 auto;
            width:310px;
            height:66px;
            // border:1px solid rgba(255,255,255,0.7);
            // border-radius:10px;
            // background:rgba(255,255,255,0.15);
            background:url('../../assets/images/index/btn-bg.png') no-repeat center center;
            background-size:100% 100%;
            display:flex;
            align-items: center;
            justify-content: center;
            font-size:30px;
            font-weight:600;
            color:#FFFFFF;
            letter-spacing: 2px;
            cursor: pointer;
            span{
              font-size:24px;
              margin-left:8px;
              font-weight:500;
              letter-spacing: 0px;
            }
          }
          .mint-number{
            margin-top:20px;
            display:flex;
            justify-content:center;
            align-items:center;
            font-size:24px;
            font-weight:500;
            .mint-numbox{
              margin-left:20px;
              display:flex;
              align-items:center;
              justify-content:center;
              border-radius: 30px;
              overflow: hidden;
              color:#FFFFFF;
              font-size:18px;
              .btn{
                width:60px;
                height:40px;
                line-height:40px;
                background:#000;
                text-align:center;
                cursor: pointer;
              }
              input{
                width:100px;
                height:40px;
                background:#000;
                border:none;
                outline: none;
                border-left:1px solid rgba(255,255,255,0.5);
                border-right:1px solid rgba(255,255,255,0.5);
                color:#FFF;
                text-align:center;
                font-size:18px;
                cursor:not-allowed
              }
            }

          }
        }
      }
      &.mint-after{
        // top:34%;
        .mint-info{
          margin: 0 auto;
          width:58%;
          height:60px;
          background:#000000;
          border-radius:33px;
          display: flex;
          justify-content:center;
          align-items:center;
          .mint-info-item{
            height:30px;
            line-height: 30px;
            font-size:20px;
            font-weight:700;
            color:#FFFFFF;
            span{
              font-size:22px;
              letter-spacing:1px;
            }
            &.transaction-amount{
              margin:0 30px;
              padding:0 30px;
              border-left:2px solid #FFFFFF;
              border-right:2px solid #FFFFFF;
            }
          }
        }
        .hodler{
          display: block;
          margin:70px auto 0;
          margin-top:calc(100%  * 70 / 1440);
          height:108px;
        }
        .check-btn{
          margin:calc(100%  * 80 / 1440) auto 0;
          width:310px;
          height:66px;
          // border:1px solid rgba(255,255,255,0.7);
          // border-radius:10px;
          // background:rgba(255,255,255,0.15);
          background:url('../../assets/images/index/btn-bg.png') no-repeat center center;
          background-size:100% 100%;
          display:flex;
          align-items: center;
          justify-content: center;
          font-size:24px;
          font-weight:600;
          color:#FFFFFF;
          letter-spacing: 2px;
          cursor: pointer;
        }
      }
    }
  }
  .footer{
    width: 100%;
    height: calc(230 / 1920 * 1440px);
    background: #f8f8f8;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    color:#010100;
    font-size:14px;
    .logo{
      width: calc(150 / 1920 * 1440px);
      margin-bottom: calc(30 / 1920 * 1440px);
    }
    .media{
      margin-top:calc(30 / 1920 * 1440px);
      display: flex;
      align-items: center;
      .media-item{
        width:calc(37 / 1920 * 1440px);
        height:calc(28 / 1920 * 1440px);
        margin:0 calc(30 / 1920 * 1440px);
        &.twitter{
          background:url('../../assets/images/index/twitter-gray.png') no-repeat center center;
          background-size: cover;
        }
      }
    }
  }
  .content{
    padding:0 0 0;
    background:#E6F0FB url('../../assets/images/index/bg-content.jpg') no-repeat top left;
    background-size:100%;
    position: relative;
    .line{
      width:100%;
      height: calc(min(100vw,1440px) * 68 / 1920);
      position: absolute;
      top:0;
      left:0;
      overflow: hidden;
      .scroll-area {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        overflow: hidden;
        img{
          width: calc(min(100vw,1440px) * 435 / 1920);
          float: left;
        }
      }
    }
    .faq-box{
      .faq-title{
        width:100%;
        height:calc(min(100vw,1440px) * 320 / 1920);
        background: url('../../assets/images/index/title-faq.png') no-repeat center center;
        background-size:auto 100%;
        display: flex;
        align-items: center;
        justify-content:center;
        font-size:28px;
        color:#000000;
        letter-spacing:4px;
        margin-bottom:calc(min(100vw,1440px) * 80 / 1920);
        .text{
          font-family: '方正兰亭粗黑';  
        }
      }
      .faq{
        width:69%;
        margin:0 auto;
        padding:calc(min(100vw,1440px) * 104 / 1440)  0 calc(min(100vw,1440px) * 100 / 1440);
        .MuiAccordion-root{
          margin-bottom:24px;
          box-shadow:none;
          border-radius:6px;
          background: #BCCEE5;
          &::before{
            display:none;
          }
          .MuiAccordionSummary-root{
            padding:0;
            .MuiAccordionSummary-content{
              margin:0;
              padding:0 0 0 90px;
              position:relative;
              .page-number{
                position:absolute;
                top:0;
                left:0;
                width:66px;
                height:51px;
                background:url('../../assets/images/index/pagenumber-bg-pink.png') no-repeat -2px -1px;
                background-size:100%;
                display:flex;
                justify-content:center;
                align-items:center;
                padding-bottom:6px;
                padding-right:10px;
                span{
                  color:#FFFFFF;
                  font-size:22px;
                  font-weight:700;
                  transform:rotate(-15deg);
                  letter-spacing:1px;
                }
              }
              .arrow{
                position:absolute;
                top:0;
                right:60px;
                width:30px;
                height:100%;
                background:url('../../assets/images/index/arrow-bottom-blue.png') no-repeat center center;
                background-size:auto 30px;
              }
              p{
                font-size:18px;
                font-weight:700;
                color:#FFFFFF;
                line-height:30px;
                padding:30px 0;
                width:calc(100% - 90px);
                font-family: '方正兰亭粗黑'; 
              }
            }
          }
          .MuiAccordionDetails-root{
            padding:0 42px 20px;
            background:url('../../assets/images/index/logo-watermark.png') no-repeat bottom right;
            background-size:40%;
            p{
              font-size:16px;
              font-weight:400;
              color:#000000;
              line-height:32px;
              margin-bottom:32px;
              &:last-child{
                margin-bottom:0;
              }
            }
          }
          .MuiButtonBase-root{
            min-height:0!important;
          }
          &:hover,&.Mui-expanded{
            background: #F5B5B5;
            .MuiAccordionSummary-root{
              .MuiAccordionSummary-content{
                .page-number{
                  background:url('../../assets/images/index/pagenumber-bg-white.png') no-repeat -2px -1px;
                  background-size:100%;
                  span{
                    color:#F5B5B5;
                  }
                }
              }
            }
          }
          &:hover{
            .MuiAccordionSummary-root{
              .MuiAccordionSummary-content{
                .arrow{
                  background:url('../../assets/images/index/arrow-bottom-white.png') no-repeat center center;
                  background-size:auto 30px;
                }
              }
            }
          }
          &.Mui-expanded{
            .MuiAccordionSummary-root{
              .MuiAccordionSummary-content{
                .arrow{
                  background:url('../../assets/images/index/arrow-top-white.png') no-repeat center center;
                  background-size:auto 30px;
                }
              }
            }
          }
        }
      }
    }
    .team{
      padding-top:calc(min(100vw,1440px) * 100 / 1920);
      position:relative;
      &::before{
        position:absolute;
        top:calc(min(100vw,1440px) * -200 / 1440);
        display:block;
        content:'';
        width:12.13%;
        padding-top:37.28%;
        background: url('../../assets/images/index/bg-pattern-1.png') no-repeat top left;
        background-size:100%;
      }
      .team-title{
        position: absolute;
        top:calc(min(100vw,1440px) * 282 / 1920);
        left:0;
        width:100%;
        height:calc(min(100vw,1440px) * 320 / 1920);
        background: url('../../assets/images/index/title-team.png') no-repeat center center;
        background-size:auto 100%;
        display: flex;
        align-items: center;
        justify-content:center;
        font-size:28px;
        color:#000000;
        letter-spacing:4px;
        .text{
          font-family: '方正兰亭粗黑';  
        }
      }
      .bg-team-box{
        width:100%;
        background: url('../../assets/images/index/bg-team3.png') repeat center center;
        background-size:100% auto;
      }
      .bg-team{
        width:100%;
      }
      .bg-team.hide{
        display:none;
      }
      .swiper{
        position:absolute;
        top:calc(min(100vw,1440px) * 750 / 1920);
        left:3%;
        width:94%;
        padding:36px 0 80px;
        &::after{
          position:absolute;
          top:-10%;
          left:0;
          display:block;
          content:'';
          width:100%;
          height:100%;
          background: url('../../assets/images/index/mask.png') no-repeat center center;
          background-size:120% 110%;
          z-index: 3;
        }
        .swiper-wrapper{
          position:relative;
          .swiper-slide{
            background:#e3ecf7;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position:relative;
            padding:20px 0 15px;
            img{
              width:80%;
              display: block;
              object-fit: cover;
            }
            .user-name{
              height:112px;
              padding:10px 20px 0;
              width:125%;
              font-size: 14px;
              line-height:20px;
              color:rgba(0,0,0,0.8);
              transform: scale(0.8);
              span{
                font-size: 16px;
                display: block;
                font-weight:bold;
                line-height:30px;
                color:rgba(0,0,0,0.85);
              }
            }
            &.swiper-slide-active{
              transform:scale(1.2);
            }
          }
        }
        .swiper-pagination{
          .swiper-pagination-bullet{
            // background:#000000;
            opacity: 1;
            &.swiper-pagination-bullet-active{
              background:#FFFFFF;
            }
          }
        }
      }
    }
    .bottom{
      display:block;
      width:100%;
    }
  }
  .loading-box{
    position: fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background:rgba(0,0,0,0.75);
    z-index: 99;
    display:flex;
    align-items: center;
    justify-content: center;
    &.hide{
      display: none;
    }
    .loading{
      display: block;
      width:24px;
      height:24px;
      -webkit-animation:whiteLoadingRotate 1.1s linear infinite;
      animation:whiteLoadingRotate 1.1s linear infinite;
    }
    @-webkit-keyframes whiteLoadingRotate{
      0%{ -webkit-transform: rotate(0deg);}
      100%{ -webkit-transform: rotate(360deg);}
    }
    @keyframes whiteLoadingRotate{
        0%{ -webkit-transform: rotate(0deg);}
        100%{ -webkit-transform: rotate(360deg);}
    }
  }
}
@media screen and (min-width: 816px) and (max-width: 1200px) {
  .page-box{
    .banner{
      position: relative;
      .banner-content{
        &.mint-before{
          .mint-date{
            font-size:26px;
            line-height:44px;
          }
          .mint-intro{
            font-size:14px;
            line-height:28px;
          }
          .mint-box{
            .mint-btn{
              width:230px;
              height:50px;
              font-size:22px;
            }
          }
        }
        &.mint{
          .mint-info{
            width:78%;
            height:44px;
            .mint-info-item{
              height:22px;
              line-height: 22px;
              font-size:14px;
              span{
                font-size:14px;
              }
              &.transaction-amount{
                margin:0 20px;
                padding:0 20px;
              }
            }
          }
          .mint-intro{
            font-size:14px;
            line-height:28px;
          }
          .mint-box{
            .mint-btn{
              width:230px;
              height:50px;
              font-size:22px;
              span{
                font-size:18px;
                margin-left:8px;
              }
            }
          }
        }
        &.mint-after{
          .mint-info{
            height:44px;
            .mint-info-item{
              height:22px;
              line-height: 22px;
              font-size:14px;
              span{
                font-size:14px;
              }
              &.transaction-amount{
                margin:0 20px;
                padding:0 20px;
              }
            }
          }
          .hodler{
            display: block;
            margin:calc(min(100vw,1440px)  * 70 / 1440) auto 0;
            height:calc(min(100vw,1440px)  * 108 / 1440);
          }
          .check-btn{
            width:230px;
            height:50px;
            font-size:16px;
          }
        }
      }
    }
    .content{
      .faq-box{
        .faq-title{
          font-size:20px;
          letter-spacing:3px;
        }
        .faq{
          width:75%;
          .MuiAccordion-root{
            .MuiAccordionSummary-root{
              .MuiAccordionSummary-content{
                padding:0 0 0 60px;
                .page-number{
                  width:50px;
                  height:43px;
                  padding-bottom:7px;
                  padding-right:10px;
                  span{
                    font-size:18px;
                  }
                }
                .arrow{
                  right:36px;
                  background:url('../../assets/images/index/arrow-bottom-blue.png') no-repeat center center;
                  background-size:auto 24px;
                }
                p{
                  font-size:18px;
                  line-height:24px;
                  padding:22px 0;
                  width:calc(100% - 60px);
                }
              }
            }
            .MuiAccordionDetails-root{
              padding:0 30px 15px;
            }
            &:hover{
              .MuiAccordionSummary-root{
                .MuiAccordionSummary-content{
                  .arrow{
                    background:url('../../assets/images/index/arrow-bottom-white.png') no-repeat center center;
                    background-size:auto 24px;
                  }
                }
              }
            }
            &.Mui-expanded{
              .MuiAccordionSummary-root{
                .MuiAccordionSummary-content{
                  .arrow{
                    background:url('../../assets/images/index/arrow-top-white.png') no-repeat center center;
                    background-size:auto 24px;
                  }
                }
              }
            }
          }
        }
      }
      .team{
        .team-title{
          font-size:20px;
          letter-spacing:3px;
        }
        .swiper{
          padding:28px 0 80px;
          &::after{
            top:28px;
          }
          .swiper-wrapper{
            position:relative;
            .swiper-slide{
              padding:20px 0 5px;
              img{
                width:80%;
              }
              .user-name{
                height:110px;
                font-size: 12px;
                line-height:18px;
                span{
                  font-size: 14px;
                  line-height:28px;
                }
              }
              &.swiper-slide-active{
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 816px) and (max-width: 1000px) {
  .page-box{
    .banner{
      position: relative;
      .banner-content{
        &.mint-before{
          .mint-date{
            font-size:26px;
            line-height:44px;
          }
          .mint-intro{
            font-size:12px;
            line-height:20px;
          }
          .mint-box{
            .mint-btn{
              width:230px;
              height:50px;
              font-size:22px;
            }
          }
        }
        &.mint{
          .mint-info{
            height:44px;
            .mint-info-item{
              height:22px;
              line-height: 22px;
              font-size:14px;
              span{
                font-size:14px;
              }
              &.transaction-amount{
                margin:0 20px;
                padding:0 20px;
              }
            }
          }
          .mint-intro{
            font-size:12px;
            line-height:20px;
          }
          .mint-box{
            .mint-btn{
              width:230px;
              height:50px;
              font-size:22px;
              span{
                font-size:18px;
                margin-left:8px;
              }
            }
          }
        }
        &.mint-after{
          .mint-info{
            height:44px;
            .mint-info-item{
              height:22px;
              line-height: 22px;
              font-size:14px;
              span{
                font-size:14px;
              }
              &.transaction-amount{
                margin:0 20px;
                padding:0 20px;
              }
            }
          }
          .hodler{
            display: block;
            margin:calc(min(100vw,1440px)  * 70 / 1440) auto 0;
            height:calc(min(100vw,1440px)  * 108 / 1440);
          }
          .check-btn{
            width:230px;
            height:50px;
            font-size:16px;
          }
        }
      }
    }
    .content{
      .faq-box{
        .faq-title{
          font-size:20px;
          letter-spacing:3px;
        }
      }
      .team{
        .team-title{
          font-size:12px;
          letter-spacing:1px;
          .text{
            transform:scale(0.8)
          }
        }
        .swiper{
          padding:28px 0 calc(min(100vw,1440px) /1920 * 220);
          // top:30%;
          left: 20px;
          width: calc(100% - 40px);
          &::after{
            display:none;
            top:20px;
            padding-top:40%;
            background-size: 112% 100%;
          }
          .swiper-wrapper{
            position:relative;
            .swiper-slide{
              padding:20px 0 0px;
              img{
                width:80%;
              }
              .user-name{
                height:120px;
                font-size: 14px;
                line-height:20px;
                span{
                  font-size: 16px;
                  line-height:30px;
                }
              }
            }
          }
          .swiper-pagination{
            .swiper-pagination-bullet{
              width:5px;
              height:5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 816px) {
  .page-box{
    .banner{
      position: relative;
      .banner-content{
        transform: translateY(calc(-50% + 18px));
        &.mint-before{
          .mint-date{
            font-size:16px;
            line-height:34px;
            letter-spacing:1px;
          }
          .mint-intro{
            display:none;
            padding:0 20px;
            font-size:12px;
            line-height:20px;
          }
          .mint-box{
            .mint-btn{
              margin: 0 auto;
              width:140px;
              height:30px;
              font-size:16px;
              border-radius:6px;
            }
          }
        }
        &.mint{
          .mint-info{
            margin-left:5%;
            width:150%;
            height:42px;
            transform:scale(0.6);
            transform-origin: left;
            .mint-info-item{
              height:20px;
              line-height: 20px;
              font-size:12px;
              span{
                font-size:12px;
              }
              &.transaction-amount{
                margin:0 6px;
                padding:0 6px;
              }
            }
          }
          .mint-intro{
            display:none;
            font-size:14px;
            line-height:28px;
          }
          .mint-box{
            margin-top:5px;
            .mint-btn{
              margin: 0 auto;
              width:140px;
              height:30px;
              font-size:16px;
              border-radius:6px;
              span{
                font-size:14px;
                margin-left:4px;
              }
            }
            .mint-number{
              margin-top:6px;
              font-size:14px;
              .mint-numbox{
                margin-left:10px;
                border-radius: 20px;
                font-size:14px;
                .btn{
                  width:36px;
                  height:26px;
                  line-height:26px;
                }
                input{
                  width:50px;
                  height: 26px;
                  font-size:14px;
                }
              }
  
            }
          }
        }
        &.mint-after{
          .mint-info{
            margin-left:5%;
            width:150%;
            height:42px;
            transform:scale(0.6);
            transform-origin: left;
            .mint-info-item{
              height:20px;
              line-height: 20px;
              font-size:12px;
              
              span{
                font-size:12px;
              }
              &.transaction-amount{
                margin:0 6px;
                padding:0 6px;
              }
            }
          }
          .hodler{
            display:none;
            margin:calc(min(100vw,1440px)  * 70 / 1440) auto 0;
            height:calc(min(100vw,1440px)  * 108 / 1440);
          }
          .check-btn{
            margin: 0 auto;
            width:200px;
            height:30px;
            font-size:14px;
            border-radius:6px;
            letter-spacing:1px;
          }
        }
      }
    }
    .footer{
      width: 100%;
      height: 100px;
      background: #f8f8f8;
      display:flex;
      flex-direction: column;
      align-items: center;
      justify-content:center;
      color:#010100;
      font-size:14px;
      .logo{
        width: 50px;
        margin-bottom: 10px;
      }
      .media{
        margin-top:20px;
        display: flex;
        align-items: center;
        .media-item{
          width:30px;
          height:30px;
          margin:0 10px;
          &.twitter{
            background:url('../../assets/images/index/twitter-gray.png') no-repeat center center;
            background-size: cover;
          }
        }
      }
    }
    .content{
      .faq-box{
        .faq-title{
          font-size:12px;
          letter-spacing:1px;
          .text{
            transform:scale(0.8)
          }
        }
        .faq{
          width:calc(100% - 40px);
          .MuiAccordion-root{
            .MuiAccordionSummary-root{
              .MuiAccordionSummary-content{
                padding:0 0 0 35px;
                .page-number{
                  width:38px;
                  height:32px;
                  span{
                    font-size:14px;
                  }
                }
                .arrow{
                  right:10px;
                  background:url('../../assets/images/index/arrow-bottom-blue.png') no-repeat center center;
                  background-size:auto 14px;
                }
                p{
                  font-size:12px;
                  line-height:20px;
                  padding:15px 0;
                  width:calc(100% - 35px);
                }
              }
            }
            .MuiAccordionDetails-root{
              padding:0 20px 10px;
              p{
                font-size:12px;
                line-height:24px;
              }
            }
            &:hover{
              .MuiAccordionSummary-root{
                .MuiAccordionSummary-content{
                  .arrow{
                    background:url('../../assets/images/index/arrow-bottom-white.png') no-repeat center center;
                    background-size:auto 14px;
                  }
                }
              }
            }
            &.Mui-expanded{
              .MuiAccordionSummary-root{
                .MuiAccordionSummary-content{
                  .arrow{
                    background:url('../../assets/images/index/arrow-top-white.png') no-repeat center center;
                    background-size:auto 14px;
                  }
                }
              }
            }
          }
        }
      }
      .team{
        .team-title{
          font-size:12px;
          letter-spacing:1px;
          .text{
            transform:scale(0.8)
          }
        }
        .swiper{
          padding:28px 0 calc(min(100vw,1440px) /1920 * 220);
          // top:33%;
          left: 20px;
          width: calc(100% - 40px);
          &::after{
            display:none;
            top:20px;
            padding-top:40%;
            background-size: 112% 100%;
          }
          .swiper-wrapper{
            position:relative;
            .swiper-slide{
              img{
                width:50%;
              }
              .user-name{
                padding:10px 20px 0;
                width:180%;
                height:85px;
                font-size: 12px;
                line-height:18px;
                transform: scale(0.55555555);
                transform-origin: top center;
                span{
                  font-size: 14px;
                  line-height:28px;
                }

              }
              &.swiper-slide-active{
                transform:scale(1.2);
              }
            }
          }
          .swiper-pagination{
            bottom:20px;
            .swiper-pagination-bullet{
              width:5px;
              height:5px;
              
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .page-box{

    .content{
      .faq-box{
        .faq-title{
          margin-bottom:calc(min(100vw,1440px) * 80 / 1920 + 32px);
        }
        .faq{
          .MuiAccordion-root{
            margin-bottom:16px;
          }
        }
      }
      .team{

        .swiper{
          padding:28px 0 calc(min(100vw,1440px) /1920 * 350);
          // top:calc(min(100vw,1440px) * 550 / 1920);
          left: 20px;
          width: calc(100% - 40px);
          .swiper-wrapper{
            position:relative;
            .swiper-slide{
              padding-top:10px;
              padding-bottom:0;
              img{
                width:80%;
              }
              .user-name{
                padding:5px;
                // width:calc(100% - 50px);
                height:65px;
                font-size: 12px;
                line-height:18px;
                span{
                  font-size: 14px;
                  line-height:28px;
                }

              }
              &.swiper-slide-active{
                transform:scale(1.2);
              }
            }
          }
          .swiper-pagination{
            bottom:20px;
            .swiper-pagination-bullet{
              width:5px;
              height:5px;
              
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .page-box{
    .content{
      .team{
        .swiper{
          .swiper-wrapper{
            .swiper-slide{
              .user-name{
                height:75px;
              }
              &.swiper-slide-active{
                transform:scale(1.2);
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 350px) {
  .page-box{
    .content{
      .team{
        .swiper{
          .swiper-wrapper{
            .swiper-slide{
              .user-name{
                height:95px;
              }
              &.swiper-slide-active{
                transform:scale(1.2);
              }
            }
          }
        }
      }
    }
  }
}
