body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: '方正兰亭粗黑';  
  src: url('./assets/fonts/方正兰亭粗黑.TTF')          
}

@font-face {
  font-family: '方正兰亭黑';  
  src: url('./assets/fonts/方正兰亭黑.TTF')          
}


* {
  box-sizing: border-box;
  -webkit-text-size-adjust:100%;
  -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
  margin:0;
  padding:0;
  outline:0;
  font-family: '方正兰亭黑';  
}


ul, li {
  list-style: none;
  margin: 0;
  padding: 0;    
}

img{
  vertical-align: middle;
}

a,a:link,a:visited,a:hover,a:active{
  text-decoration: none;
  color:inherit;
}
.ant-message{
  margin-top: 100px!important;
}