.music-box{
  position: fixed;
  left:30px;
  bottom:50px;
  z-index:90;
  .icon{
    position: absolute;
    bottom: 0;
    left:-12px;
    width:130px;
  }
  .button{
    position: absolute;
    top: -10px;
    right:-10px;
    width:30px;
    height:30px;
    border-radius:50%;
    background:#FFFFFF;
    border:1px solid rgba(100,100,100,0.2);
    font-size:18px;
    font-weight:bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.init{
      img{
        width:10px;
        margin-left:3px;
      }
    }
  }
  .audio{
    display:none;
  }
  .music{
    width:calc(min(100vw - 40px,475px));
    height:73px;
    background:#FFFFFF;
    border-radius:6px;
    padding-left:126px;
    padding-right:24px;
    display: flex;
    justify-content:space-between;
    align-items:center;
    box-shadow: 0px 1px 30px 0px rgba(0,0,0,0.1);
    &.hide{
      width:192px;
      .info,.playicon{
        display:none;
      }
    }
    .playingicon{
      width:30px;
      height:36px;
      position: relative;
      display: inline-flex;
      justify-content: space-between;
      align-items: flex-end;
      // padding: 0.84rem 0;
      transition: all 1s ease;
      &.static{
        span{
          height:2px;
          animation: none !important;
          -webkit-animation:none!important;
        }
      }
      span{
        content: "";
        width: 5px;
        height: 100%;
        border-radius: 3px;
        background-color: #5b9069!important;
        margin: 0 1.5px;
        transform-origin: bottom;
        transition: opacity .3s ease-out;
        &:nth-child(1){
          -webkit-animation: bounce 1s ease infinite 0.2s;
          animation: bounce 1s ease infinite 0.2s;
        }
        &:nth-child(3){
          -webkit-animation: bounce 1.2s ease infinite 0.5s;
          animation: bounce 1.2s ease infinite 0.5s;
        }
        &:nth-child(2){
          -webkit-animation: bounce 1.4s ease infinite;
          animation: bounce 1.4s ease infinite;
        }
      }
      @keyframes bounce{
				0%{
					height: 0%;
				}
				20%{
					height: 50%;
				}
				50%{
					height: 100%;
				}
				80%{
					height: 50%;
				}
				100%{
					height: 0%;
				}
			}	
  
    }
    .info{
      // display:none;
      margin:0 20px;
      width:20px;
      flex:1 1;
      .name{
        font-size:16px;
        font-weight:bold;
        color:rgba(0,0,0,1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height:20px;
      }
      .intro{
        font-size:14px;
        color:rgba(0,0,0,0.4);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height:16px;
        margin-top:6px;
      }
    }
    .playicon{
      width:132px;
      min-width:132px;
      display: flex;
      justify-content:space-between;
      align-items:center;
      .prev{
        width:22px;
        height:19px;
        background:url('../../assets/images/music/prev.png') no-repeat center center;
        background-size:22px auto;
        cursor: pointer;
      }
      .next{
        width:22px;
        height:19px;
        background:url('../../assets/images/music/next.png') no-repeat center center;
        background-size:22px auto;
        cursor: pointer;
      }
      .play{
        width:20px;
        height:23px;
        background:url('../../assets/images/music/play.png') no-repeat center center;
        background-size:20px auto;
        cursor: pointer;
        &.playing{
          background:url('../../assets/images/music/pause.png') no-repeat center center;
          background-size:20px auto;
        }
      }
    }
  }
}

@media screen and (max-width: 816px) {
  .music-box{
    position: fixed;
    left:30px;
    bottom:30px;
    z-index:90;
    .icon{
      position: absolute;
      bottom: 0;
      left:-8px;
      width:86px;
    }
    .button{
      position: absolute;
      top: -6px;
      right:-6px;
      width:20px;
      height:20px;
      border-radius:50%;
      background:#FFFFFF;
      border:1px solid rgba(100,100,100,0.2);
      font-size:14px;
      font-weight:bold;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height:16px;
      cursor: pointer;
      &.init{
        img{
          width:7px;
          margin-left:2px;
        }
      }
    }
    .audio{
      display:none;
    }
    .music{
      width:calc(min(100vw - 40px,316px));
      height:48px;
      background:#FFFFFF;
      border-radius:6px;
      padding-left:84px;
      padding-right:16px;
      display: flex;
      justify-content:space-between;
      align-items:center;
      box-shadow: 0px 1px 30px 0px rgba(0,0,0,0.1);
      &.hide{
        width:128px;
        .info,.playicon{
          display:none;
        }
      }
      .playingicon{
        width:20px;
        height:27px;
        position: relative;
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-end;
        // padding: 0.84rem 0;
        transition: all 1s ease;
        &.static{
          span{
            height:2px;
            animation: none !important;
            -webkit-animation:none!important;
          }
        }
        span{
          content: "";
          width: 5px;
          height: 100%;
          border-radius: 3px;
          background-color: #5b9069!important;
          margin: 0 1.5px;
          transform-origin: bottom;
          transition: opacity .3s ease-out;
          &:nth-child(1){
            -webkit-animation: bounce 1s ease infinite 0.2s;
            animation: bounce 1s ease infinite 0.2s;
          }
          &:nth-child(3){
            -webkit-animation: bounce 1.2s ease infinite 0.5s;
            animation: bounce 1.2s ease infinite 0.5s;
          }
          &:nth-child(2){
            -webkit-animation: bounce 1.4s ease infinite;
            animation: bounce 1.4s ease infinite;
          }
        }
        @keyframes bounce{
          0%{
            height: 0%;
          }
          20%{
            height: 50%;
          }
          50%{
            height: 100%;
          }
          80%{
            height: 50%;
          }
          100%{
            height: 0%;
          }
        }	
    
      }
      .info{
        // display:none;
        margin:0 12px;
        flex:1 1;
        width:20px;
        .name{
          font-size:14px;
          line-height:18px;
        }
        .intro{
          font-size:12px;
          line-height:16px;
          margin-top:0;
        }
      }
      .playicon{
        min-width:88px;
        width:88px;
        display: flex;
        justify-content:space-between;
        align-items:center;
        .prev{
          width:15px;
          height:14px;
          background:url('../../assets/images/music/prev.png') no-repeat center center;
          background-size:15px auto;
          cursor: pointer;
        }
        .next{
          width:15px;
          height:14px;
          background:url('../../assets/images/music/next.png') no-repeat center center;
          background-size:15px auto;
          cursor: pointer;
        }
        .play{
          width:14px;
          height:16px;
          background:url('../../assets/images/music/play.png') no-repeat center center;
          background-size:14px auto;
          cursor: pointer;
          &.playing{
            background:url('../../assets/images/music/pause.png') no-repeat center center;
            background-size:14px auto;
          }
        }
      }
    }
  }
}
